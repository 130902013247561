
export type DayTypeName = {
    '-1': 'Не определено',
    0:	'Рабочий день',
    1:	'Выходной',
    2:	'Больничный',
    3:	'Пропуск по неуважительной причине',
    4:	'Отпуск',
    5:	'Отпуск без содержания',
    6:	'Учебный отпуск',
    7:	'Период до приема на работу в КЦ',
    8:	'Увольнение',
    9:	'Невыход'
}

export type DayTypeShortName = {
    '-1': '',
    0: '',
    1: 'В',
    2: 'Б',
    3: 'Пр',
    4: 'ОТ',
    5: 'ДО',
    6: 'У',
    7: '-',
    8: 'УВ',
    9: 'Н'
}

export enum DayType {
    Unknown = -1,
    Work,
    DayOff,
    SickLeave
}

export interface DayTypeDb {
    id: number,
    caption: string,
    icon: string
}