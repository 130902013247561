import { Spin, Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ResultStatusType } from 'antd/lib/result';
import React, { useState, useEffect,  } from 'react';
import { StringParam, withDefault, ArrayParam, useQueryParams } from 'use-query-params';
import { ConfigProvider, Empty } from 'antd';
import './App.css';
import Board from './Component/Board';
import { IAuthData } from './DbContext/AuthData';
import { authUser, storeItemNameOfToken } from './DbContext/DbProvider';
import { isNullOrUndefined, isUndefined } from 'util';
import ruRu from 'antd/lib/locale/ru_RU';

const size = "small";
const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface IAuthAppData {
    PID?: string | null,
    authToken?: string | null
}

function App() {

    const [isLoading, setIsLoading] = useState(true);
    const [authData, setAuthData] = useState<IAuthData>();
    const [resultStatus, setResultStatus] = useState<ResultStatusType>();
    const [resultTitle, setResultTitle] = useState<string>();
    const [resultBody, setResultBody] = useState<JSX.Element>();

    const [query, setQuery] = useQueryParams({
        PID: StringParam,
        authToken: StringParam,
        filters: withDefault(ArrayParam, []),
    });

    const auth = async ({ authToken, PID }: IAuthAppData) => {

        setIsLoading(true);

        let is404 = true;

        const auth404 = () => {
            setResultStatus(404);
            setResultTitle("Страница не найдена");
            setResultBody(<ul>
                <li>AuthToken: {authToken}</li>
                <li>PID: {PID}</li>
            </ul>);
        }

        const auth500 = (ex: unknown) => {
            setResultStatus(500);
            setResultTitle(`Ошибка авторизации`);
            setResultBody(<ul>
                <li>AuthToken: {authToken}</li>
                <li>PID: {PID}</li>
                <li>Error: {JSON.stringify(ex)}</li>
            </ul>);

            console.error(ex);
        }

        if (authToken && PID) {

            try {

                const response = await authUser({
                    authToken: authToken
                });

                if (!isNullOrUndefined(response.data) &&
                    !isNullOrUndefined(response.data.token) &&
                    !isNullOrUndefined(response.data.id) &&
                    response.data.token != '' &&
                    response.data.id > 0
                ) {
                    localStorage.setItem(storeItemNameOfToken, response.data.token);
                    setAuthData({
                        userId: response.data.id,
                        userType: response.data.type,
                        PID: PID
                    });
                    is404 = false;
                }
            }
            catch (ex: unknown) {
                is404 = false;
                auth500(ex);
            }
        }

        if (is404) {
            auth404();
        }

        setIsLoading(false);
    }

    useEffect(() => {

        auth(query);

    }, [query]);

    return (
        <ConfigProvider
            locale={ruRu}
        >
            <div className="App">
                {isLoading &&
                    <div className="spin-center">
                        <Spin
                            indicator={loadingIcon}
                        />
                    </div>
                }
                {!isLoading && !authData &&
                    <Result
                        status={resultStatus}
                        title={resultTitle}
                    >
                        {resultBody}
                    </Result>
                }
                {!isLoading &&
                  authData &&
                <Board
                    authData={authData}
                    size={size}
                    disabledMultiEdit={false}
                />}
            </div>
        </ConfigProvider>
    );
}

export default App;
