import React, { useState, useEffect, ReactElement, useRef, createRef } from 'react';
import Moment from 'moment';
import { IExtractFilter } from '../DbContext/ExtractFilter';
import { IOperatorItem } from '../DbContext/OperatorItem';
import { IHeaderItem } from '../DbContext/HeaderItem';
import { IScheduleItem } from '../DbContext/ScheduleItem';
import { IHistoryItem } from '../DbContext/HistoryItem';
import { message, Table, TableColumnsType, TableColumnType, Select, Divider, Row, Col, Modal, Button } from 'antd';
import { fetchOperators, fetchHeaderItems, fetchScheduleItems, fetchCalendarOptions, fetchHistory, fetchIntervals, fetchRequestStatusParameters, fetchScheduleContextMenu } from '../DbContext/DbProvider';
import { IScheduleDataTableRow } from '../Entity/ScheduleDataTableRow';
import { DataTableItemProxy } from '../Entity/ScheduleDataTableItemProxy';
import { ICalendarOptionItem } from '../DbContext/CalendarOptionItem';
import { IAuthData } from '../DbContext/AuthData';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ModalScheduleItemEdit } from './ModalScheduleItemEdit';
import { ModalScheduleMultiItemEdit } from './ModalScheduleMultiItemEdit';

import {HistoryOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { compileClassNames, displaySysErrorInModal, nameof, sleep } from '../helpers';

import 'antd/dist/antd.css';
import './Board.css';
import './Colors.css';

import { isNullOrUndefined, isString, isUndefined } from 'util';
import { IRequestStatusParameter } from '../DbContext/RequestStatusParameter';
import BoardContextMenu, { BoardContextMenuProps } from './BoardContextMenu';
import { IContextMenuItem } from '../DbContext/ContextMenuItem';
import ModalWindowScheduleActionEdit, { IDayEditData } from './ModalScheduleActionEdit';
import { IScheduleItemWithoutRNXY } from '../DbContext/ScheduleItemWithoutRNXY';

const { Option } = Select;

export interface BoardProps {
    authData: IAuthData,
    size: SizeType,
    disabledMultiEdit?: boolean,
    disabledSignEdit?: boolean
}

interface IScheduleDataTableColumn extends TableColumnType<IScheduleDataTableRow> { }
interface IHistoryDataTableColumn extends TableColumnType<IHistoryItem> { }
interface IRequestStatusTableColumn extends TableColumnType<IRequestStatusParameter> { }

let internalCounter = 0;

const unsetScheduleId = -1;
const defaultNoSelectedHistoryValue = -1;
const defaultNoSelectedCalendarDayValue = -1;
const defaultTableHeight = 800;
const defaultTableValueWidth = 75;

const defaultCalendarData = [{ id: defaultNoSelectedCalendarDayValue, caption: "Загрузка..." }];

const scheduleItemClassName = 'schedule-item';

function updateItemInData(newData: IScheduleDataTableRow[], item: IScheduleItemWithoutRNXY) {

    //console.log(selectedShiftItem, item);

    const index = newData.findIndex(newDataRow =>
        //console.log(newDataRow) == void 0 && 
        newDataRow.items &&
        newDataRow.items.findIndex(shiftItemInNewData =>
            //console.log(shiftItemInNewData) == void 0 &&
            shiftItemInNewData &&
            shiftItemInNewData.scheduleId == item.scheduleId) != -1
    );

    if (index != -1) {

        const row = newData[index];

        if (!isUndefined(row.items)) {

            const shiftIndex = row.items.findIndex(shiftItemInNewData =>
                //console.log(shiftItemInNewData) == void 0 &&
                shiftItemInNewData &&
                shiftItemInNewData.scheduleId == item.scheduleId);

            if (shiftIndex != -1) {

                row.items[shiftIndex] = {
                    ...row.items[shiftIndex],
                    ...item
                }

                return newData;
            }
        }
    }

    throw new Error(`Failed find row has shift with scheduleId: ${item.scheduleId}`);
}

function updateItemsInData(newData: IScheduleDataTableRow[], items: IScheduleItemWithoutRNXY[]) {

    items.map(item => {

        newData = updateItemInData(newData, item);
    });

    return newData;
}

export function Board(props: BoardProps) {

    const { authData, size, disabledMultiEdit, disabledSignEdit } = props;

    const boardRef = useRef<HTMLDivElement>(null);

    const [scheduleTableId]      = useState(() => `board-schedule-table-${internalCounter++}`);
    const [historyTableId]       = useState(() => `board-history-table-${internalCounter++}`);
    const [requestStatusTableId] = useState(() => `board-request-status-table-${internalCounter++}`);
    const [showDetail, setShowDetail] = useState(false);
    const [boardContextMenuState, setBoardContextMenuState] = useState<BoardContextMenuProps>({});
    const [boardContextMenuItems, setBoardContextMenuItems] = useState<IContextMenuItem[]>([]);

    const [selectedCalendarValue, setSelectedCalendarValue] = useState(defaultNoSelectedCalendarDayValue);
    
    const [visibleEditModal, setVisibleEditModal] = useState(false);
    const [editModalTitle, setEditModalTitle] = useState<string>();

    const [visibleMultiEditModal, setVisibleMultiEditModal] = useState(false);
    const [multiEditModalData, setMultiEditModalData] = useState<IScheduleDataTableRow>();

    const [visibleActionEditModal, setVisibleActionEditModal] = useState(false);
    const [editActionModalTitle, setEditActionModalTitle] = useState<string>();
    const [actionEditModalData, setActionEditModalData] = useState<IDayEditData>();

    const [scheduleColumns, setScheduleColumns] = useState<IScheduleDataTableColumn[]>([]);
    const [scheduleDataSource, setScheduleDataSource] = useState<IScheduleDataTableRow[]>([]);
    const [calendarData, setCalendarData] = useState<ICalendarOptionItem[]>(defaultCalendarData);
    const [isLoadingCalendar, setIsLoadingCalendar] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [historySelectValue, setHistorySelectValue] = useState(defaultNoSelectedHistoryValue);
    const [historyDataSource, setHistoryDataSource] = useState<IHistoryItem[]>([]);
    const [historyColumns, setHistoryColumns] = useState<IHistoryDataTableColumn[]>([
        {
            ellipsis:  true,
            title:     "Дата",
            key:       `${nameof<IHistoryItem>("id")}`,
            width:     100,
            render: (text, record, index) => {

                if (record) {
                    return Moment(record.dateTime).format('DD.MM.YYYY');
                }

                return text;
            }
        },
        {
            ellipsis: true,
            title:    "Старое значение",
            key:      `${nameof<IHistoryItem>("oldSCaption")}`,
            width:    defaultTableValueWidth,
            render: (text, record, index) => {

                if (record) {
                    const item = record;
                    return (
                        <span>
                            <span>{item.oldSCaption}</span>
                            <span>{item.oldECaption}</span>
                        </span>
                    );
                }

                return text;
            }
        },
        {
            ellipsis: true,
            title:    "Новое значение",
            key:      `${nameof<IHistoryItem>("newSCaption")}`,
            width:    defaultTableValueWidth,
            render: (text, record, index) => {

                if (record) {
                    const item = record;
                    return (
                        <span>
                            <span>{item.newSCaption}</span>
                            <span>{item.newECaption}</span>
                        </span>
                    );
                }

                return text;
            }
        },
        {
            ellipsis:  true,
            title:     "Автор",
            key:       `${nameof<IHistoryItem>("author")}`,
            dataIndex: `${nameof<IHistoryItem>("author")}`,
            width:      100
        }
    ]);

    useEffect(() => {

        const hideContextMenu = () => {
            setBoardContextMenuState({ visible: false });
        }

        window.addEventListener("click", hideContextMenu);
        window.addEventListener("contextmenu", hideContextMenu);
        return () => {
            window.removeEventListener("click", hideContextMenu);
            window.removeEventListener("contextmenu", hideContextMenu);
        }
    }, []);

    const [isLoadingRequestStatus, setIsLoadingRequestStatus] = useState(false);
    const [requestStatusDataSource, setRequestStatusDataSource] = useState<IRequestStatusParameter[]>([]);
    const [requestStatusColumns, setRequestStatusColumns] = useState<IRequestStatusTableColumn[]>([
        {
            ellipsis:  true,
            title:     'Описание',
            align:     'left',
            key:       `${nameof<IRequestStatusParameter>("name")}`,
            dataIndex: `${nameof<IRequestStatusParameter>("name")}`
        },
        {
            ellipsis:  true,
            title:     'Значение',
            align:     'left',
            key:       `${nameof<IRequestStatusParameter>("value")}`,
            dataIndex: `${nameof<IRequestStatusParameter>("value")}`
        },
    ]);

    const [selectedShiftItem, setSelectedShiftItem] = useState<IScheduleItem>();

    let selectedRecordCell: IScheduleItem | undefined;

    function clearPropertyLayout() {
        setHistoryDataSource([]);
        setRequestStatusDataSource([]);
    }

    function clearBoardDataSources() {
        clearPropertyLayout();
        setScheduleColumns([]);
        setScheduleDataSource([]);
    }

    const reloadPropertyLayout: React.EffectCallback = () => {

        clearPropertyLayout();

        if (isUndefined(selectedShiftItem)) {
            return;
        }

        let ignore = false;

        setIsLoadingHistory(true);
        setIsLoadingRequestStatus(true);

        const currectScheduleId = selectedShiftItem.scheduleId;
        const params = {
            ...authData,
            scheduleId: currectScheduleId
        };

        fetchHistory(params)
            .then(
                (response) => {

                    if (ignore || currectScheduleId != selectedShiftItem.scheduleId) return;

                    setHistoryDataSource(response.data);
                },
                (err) => {

                    if (ignore || currectScheduleId != selectedShiftItem.scheduleId) return;

                    displaySysErrorInModal(err, {
                        title: 'Упс!',
                        content: 'Не удалось загрузить данные'
                    });
                }
            )
            .finally(() => {

                if (ignore || currectScheduleId != selectedShiftItem.scheduleId) return;

                setIsLoadingHistory(false);
            });

        fetchRequestStatusParameters(params)
            .then(
                (response) => {

                    if (ignore || currectScheduleId != selectedShiftItem.scheduleId) return;

                    setRequestStatusDataSource(response.data);
                },
                (err) => {

                    if (ignore || currectScheduleId != selectedShiftItem.scheduleId) return;

                    displaySysErrorInModal(err, {
                        title: 'Упс!',
                        content: 'Не удалось загрузить данные'
                    });
                }
            )
            .finally(() => {

                if (ignore || currectScheduleId != selectedShiftItem.scheduleId) return;

                setIsLoadingRequestStatus(false);
            });

        return () => { ignore = true; }

    };

    useEffect(reloadPropertyLayout, [selectedShiftItem]);

    const SelectedShiftItem = (shiftItem: IScheduleItem) => {
        selectedRecordCell = shiftItem;
        setSelectedShiftItem(shiftItem);
    }

    const onDoubleClickTableRowCell = (event: React.MouseEvent<HTMLTableDataCellElement>, record: IScheduleDataTableRow, shiftItem: IScheduleItem) => {
        SelectedShiftItem(shiftItem);
        setEditModalTitle(`Редактирование графика ${record.operatorName} за ${Moment(shiftItem.dateTime).format("DD.MM.YYYY")}`);
        setVisibleEditModal(true);
    }

    const onClickTableRowCell = (event: React.MouseEvent<HTMLTableDataCellElement>, record: IScheduleDataTableRow, shiftItem: IScheduleItem) => {
        SelectedShiftItem(shiftItem);
    }

    const getColumns = async (filter: IExtractFilter) => {

        const headerResponse = await fetchHeaderItems(filter);
        const columns: TableColumnsType<IScheduleDataTableRow> = [];

        columns.push({
            ellipsis:  true,
            title:     "Сотрудник",
            key:       `${nameof<IScheduleDataTableRow>("operatorId")}}`,
            dataIndex: `${nameof<IScheduleDataTableRow>("operatorName")}`,
            width:     180,
            fixed:     "left",
            onCell: (record) => {

                if (disabledMultiEdit) {
                    return {};
                }

                const operatorDisabled = isUndefined(record.items)
                    || record.items.filter(x => !x.isDisabled && x.scheduleId != unsetScheduleId).length == 0;

                const onDoubleClickTableRowCellItem = async (event: React.MouseEvent<HTMLTableDataCellElement>) => {

                    if (operatorDisabled) {
                        Modal.info({
                            title: 'Упс!',
                            content: 'Сотрудник не доступен для редактирования.',
                        });
                        return;
                    }

                    setMultiEditModalData(record);
                    setVisibleMultiEditModal(true);
                }

                const columnElement: React.TdHTMLAttributes<HTMLTableDataCellElement> = {
                    onDoubleClick: onDoubleClickTableRowCellItem,
                }

                return columnElement;
            }
        });

        headerResponse.data.map((headerItem, headerIndex) => {

            columns.push({
                width: defaultTableValueWidth,
                ellipsis: true,
                title: headerItem.caption,
                key: `${headerItem.calendarDayId}_${nameof<IScheduleItem>("scheduleId")}`,
                onCell: (record) => {

                    if (isNullOrUndefined(record.items)) {
                        throw new Error(`Items is null or indefined in record: ${record.key}`);
                    }

                    const shiftItem = record.items[headerItem.calendarDayId];

                    if (isNullOrUndefined(shiftItem)) {
                        throw new Error(`Record ${record.key} has not cell for day ${headerItem.calendarDayId}`);
                    }

                    const { backgroundColor, isDisabled, scheduleId, contextMenu } = shiftItem;

                    const noScheduleId = scheduleId == unsetScheduleId;

                    let clickInitTimeout: number | null = null;

                    const onDoubleClickTableRowCellItemWrap = (event: React.MouseEvent<HTMLTableDataCellElement>) => {

                        if (isDisabled || noScheduleId)
                            return;

                        if (clickInitTimeout != null) {
                            window.clearTimeout(clickInitTimeout);
                            clickInitTimeout = null;
                        }

                        onDoubleClickTableRowCell(event, record, shiftItem);
                    }

                    const onClickTableRowCellItemWrap = (event: React.MouseEvent<HTMLTableDataCellElement>) => {

                        if (isDisabled || noScheduleId)
                            return;

                        if (clickInitTimeout == null) {
                            clickInitTimeout = window.setTimeout(() => {
                                clickInitTimeout = null;
                                onClickTableRowCell(event, record, shiftItem);
                            }, 300);
                        }
                    }

                    const onContextMenuTableRowCellItemWrap = (event: React.MouseEvent<HTMLTableCellElement>) => {

                        event.preventDefault();

                        if(contextMenu) {

                            event.stopPropagation();

                            setBoardContextMenuState({
                                visible: true,
                                record: record,
                                item: shiftItem,
                                x: event.clientX,
                                y: event.clientY
                            });
                            
                            SelectedShiftItem(shiftItem);
                        }
                    }

                    const editable = !isDisabled && !noScheduleId;
                    const hasStatus = editable && backgroundColor.toLowerCase().trim() != 'white';

                    const classList = [
                        `${scheduleItemClassName}`,
                        `${(isDisabled || noScheduleId) ? 'not-allowed' : ''}`,
                        `${(isDisabled) ? 'disabled' : ''}`,
                        `${hasStatus ? 'has-status' : ''}`,
                        `${hasStatus ? backgroundColor + 'BeforeBg' : ''}`,
                        `${selectedRecordCell && selectedRecordCell.scheduleId == scheduleId ? 'focused' : ''}`,
                        `${editable ? 'editable' : ''}`
                    ];

                    const columnElement: React.TdHTMLAttributes<HTMLTableDataCellElement> = {
                        className:      compileClassNames(classList),
                        onDoubleClick:  onDoubleClickTableRowCellItemWrap,
                        onClick:        onClickTableRowCellItemWrap,
                        onContextMenu:  onContextMenuTableRowCellItemWrap
                    }

                    return columnElement;
                },
                render: (text, record, index) => {

                    if (record.items) {
                        const item = record.items[headerItem.calendarDayId];
                        return (
                            <span>
                                <span>{item.sCaption}</span>
                                <span>{item.eCaption}</span>
                            </span>
                        );
                    }

                    return text;
                }
            });
        });

        return columns;
    }

    const fetchData = async (filter: IExtractFilter) => {

        try {
            setIsLoadingData(true);
            clearBoardDataSources();

            const columns = await getColumns(filter);

            setScheduleColumns(columns);

            const operatorsResponse = await fetchOperators(filter);
            const shiftResponse = await fetchScheduleItems(filter);

            const dataSources = operatorsResponse.data.map<IScheduleDataTableRow>(operator => {

                const dataRow: IScheduleDataTableRow = DataTableItemProxy.BuildProxy({
                    key: `${operator.id}`,
                    operator: operator,
                    operatorId: operator.id,
                    operatorName: operator.caption
                });

                dataRow.items = new Array<IScheduleItem>();

                shiftResponse.data.map(shiftItem => {

                    if (shiftItem.userId == operator.id && dataRow.items) {
                        dataRow.items[shiftItem.calendarDayId] = shiftItem;
                    }
                });

                return dataRow;
            });

            return dataSources;
        }
        finally
        {
            setIsLoadingData(false);
        }
    }

    useEffect(() => {

        let ignore = false;

        setIsLoadingCalendar(true);
        setSelectedCalendarValue(defaultNoSelectedCalendarDayValue);
        setCalendarData(defaultCalendarData);

        fetchCalendarOptions(authData)
            .then(
                response => {

                    if (ignore) return;

                    const data = response.data;

                    if (data.length > 0) {

                        if (data.findIndex(e => e.id == defaultNoSelectedHistoryValue) == -1) {
                            data.unshift({
                                id: defaultNoSelectedHistoryValue,
                                caption: "Не выбрано"
                            });
                        }

                        setCalendarData(data);
                        setIsLoadingCalendar(false);
                    }
                },
                err => {

                    if (ignore) return;

                    displaySysErrorInModal(err, {
                        title: 'Упс!',
                        content: 'Не удалось загрузить данные'
                    });
                }
            )
            .finally(() => {

            if (ignore) return;

            setIsLoadingCalendar(false);
        });

        return () => { ignore = true; }

    }, [authData]);

    const updateBoard: React.EffectCallback = () => {

        clearBoardDataSources();
        setBoardContextMenuItems([]);

        if (selectedCalendarValue == defaultNoSelectedCalendarDayValue) {
            return;
        }

        let ignore = false;

        const currectCalendarValue = selectedCalendarValue;
        const filter: IExtractFilter = {
            ...authData,
            calendarPeriodId: currectCalendarValue,
            isFromToday: false
        };

        fetchScheduleContextMenu(filter).then(response => setBoardContextMenuItems(response.data))

        fetchData(filter)
            .then(
                data => {

                    if (ignore || currectCalendarValue != selectedCalendarValue) return;

                    setScheduleDataSource(data);
                },
                err => {

                    if (ignore || currectCalendarValue != selectedCalendarValue) return;

                    displaySysErrorInModal(err, {
                        title: `Упс!`,
                        content: `Не удалось загрузить данные`
                    });
                }
            );

        return () => { ignore = true; }

    }

    useEffect(updateBoard, [selectedCalendarValue]);

    const onChangeCalendar = (value: number) => {

        setSelectedCalendarValue(value);
    }

    const onUpdateBtnClick = () => {

        updateBoard();
    }

    function onUpdateShiftItems(items: IScheduleItemWithoutRNXY[]) {

        try {
            const newData = [...scheduleDataSource];
            const changedData = updateItemsInData(newData, items);

            setScheduleDataSource(changedData);
            reloadPropertyLayout();
        }
        catch (ex: unknown) {
            displaySysErrorInModal(ex);
        }
    }

    function onUpdateShiftItem(item: IScheduleItem) {

        try {
            const newData = [...scheduleDataSource];
            const changedData = updateItemInData(newData, item);

            setScheduleDataSource(changedData);
            reloadPropertyLayout();
        }
        catch (ex: unknown) {
            displaySysErrorInModal(ex);
        }
    }

    function onVisibleEditModalChange(newState: boolean) {
        setVisibleEditModal(newState);
    }

    function onVisibleActionEditModalChange(newState: boolean) {
        setVisibleActionEditModal(newState);
    }

    function onVisibleMultiEditModalChange(newState: boolean) {
        setVisibleMultiEditModal(newState);
    }

    const tableWrapRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(defaultTableHeight);

    function handleResize() {

        if (tableWrapRef && tableWrapRef.current) {

            let heightOffset = 0;

            if (boardRef.current) {

                const style = window.getComputedStyle(boardRef.current);

                heightOffset += parseFloat(style.marginBottom);
                heightOffset += parseFloat(style.paddingBottom);
            }

            const tableWrapBound = tableWrapRef.current.getBoundingClientRect();
            const table          = document.getElementById(scheduleTableId);

            if (isNullOrUndefined(table)) {
                throw new Error('Table not found');
            }

            const header = getTableHeader(table);

            if (isNullOrUndefined(header)) {
                throw new Error('Table header not found');
            }

            heightOffset += header.getBoundingClientRect().height;

            const height = window.innerHeight - tableWrapBound.top - heightOffset;

            setTableHeight(height);
        }
    }

    useEffect(() => {

        let resizeTimeout = setTimeout(() => handleResize(), 300);
        return () => { clearTimeout(resizeTimeout); }

    }, [scheduleColumns]);

    useEffect(() => {

        handleResize();

        let resizeTimeout: number | undefined;

        const handleResizeWrap = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = window.setTimeout(() => {
                handleResize();
            }, 300);
        }

        window.addEventListener('resize', handleResizeWrap);

        return () => {
            clearTimeout(resizeTimeout);
            window.removeEventListener('resize', handleResizeWrap)
        }
    }, []);

    function getTableHeader(table: HTMLElement) {
        return table.querySelector<HTMLElement>('.ant-table-container > .ant-table-header');
    }

    useEffect(() => {

        let ignore = false;

        const tableBody = document.querySelector<HTMLElement>(`#${scheduleTableId} > .ant-table-container > .ant-table-body`);

        if (tableBody) {

            const firstCellItem = tableBody.querySelector<HTMLElement>('table > .ant-table-tbody > .ant-table-row > td:first-child');
            const firstEditableItem = tableBody.querySelector<HTMLElement>(`.${scheduleItemClassName}.editable`);

            if (firstCellItem && firstEditableItem && !ignore) {

                tableBody.scrollTo(firstEditableItem.offsetLeft - firstCellItem.getBoundingClientRect().width, 0);
            }
        }

        return () => { ignore = true; }

    }, [showDetail, scheduleDataSource]);

    return (
        <div ref={boardRef} className="board">
            <div
                className="ap-switcher-wrapper"
                onClick={() => setShowDetail((val) => !val)}
            >
                <div
                    className="ap-switcher-icon"
                >
                    {!showDetail && <HistoryOutlined />}
                    { showDetail && <CloseCircleOutlined />}
                </div>
            </div>
            {disabledSignEdit !== true &&
                <ModalScheduleItemEdit
                    size='middle'
                    title={editModalTitle}
                    authData={authData}
                    shiftItem={selectedShiftItem}
                    visible={visibleEditModal}
                    onVisibleChange={onVisibleEditModalChange}
                    onUpdateShift={onUpdateShiftItem}
                />
            }
            {disabledMultiEdit !== true &&
                <ModalScheduleMultiItemEdit
                    size={size}
                    authData={authData}
                    visible={visibleMultiEditModal}
                    data={multiEditModalData}
                    onUpdateShiftItems={onUpdateShiftItems}
                    onVisibleChange={onVisibleMultiEditModalChange}
                />
            }
            <ModalWindowScheduleActionEdit
                size={size}
                title={editActionModalTitle}
                authData={authData}
                visible={visibleActionEditModal}
                shiftItem={selectedShiftItem}
                onUpdateShiftItems={onUpdateShiftItems}
                onVisibleChange={onVisibleActionEditModalChange}
            />
            <Select<number, ICalendarOptionItem>
                style={{ width: 180 }}
                defaultValue={historySelectValue}
                onChange={onChangeCalendar}
                loading={isLoadingCalendar}
                disabled={isLoadingData || isLoadingCalendar}
            >
                {calendarData.length > 0 && calendarData.map((calendarDataItem, index) => {
                    return (<Option key={calendarDataItem.id} value={calendarDataItem.id}>{calendarDataItem.caption}</Option>);
                })}
            </Select>
            <Divider type="vertical" />
            <Button disabled={isLoadingData || isLoadingCalendar} onClick={onUpdateBtnClick}>Обновить</Button>
            <Divider />
            <div ref={tableWrapRef}>
                <Row gutter={16}>
                    <Col
                        span={showDetail ? 18 : 24}
                    >
                        <Table<IScheduleDataTableRow>
                            id={scheduleTableId}
                            rowKey={`${nameof<IScheduleDataTableRow>("operatorId")}`}
                            size={size}
                            loading={isLoadingData}
                            dataSource={scheduleDataSource}
                            columns={scheduleColumns}
                            pagination={false}
                            bordered={true}
                            scroll={{ x: '100%', y: tableHeight }}
                        />
                    </Col>
                    <Col
                        span={6}
                        style={!showDetail ? {display: "none"} : {}}
                    >
                        <Divider orientation="left" orientationMargin="0" style={{ marginTop: 0 }}>
                            Информация
                        </Divider>
                        <Table<IRequestStatusParameter>
                            id={requestStatusTableId}
                            rowKey={`${nameof<IRequestStatusParameter>("name")}`}
                            size={size}
                            loading={isLoadingRequestStatus}
                            dataSource={requestStatusDataSource}
                            columns={requestStatusColumns}
                            pagination={false}
                            bordered={true}
                            scroll={{ x: '100%', y: (tableHeight - 135) / 2 }}
                        />
                        <Divider orientation="left" orientationMargin="0">
                            История
                        </Divider>
                        <Table<IHistoryItem>
                            id={historyTableId}
                            rowKey={`${nameof<IHistoryItem>("id")}`}
                            size={size}
                            loading={isLoadingHistory}
                            dataSource={historyDataSource}
                            columns={historyColumns}
                            pagination={false}
                            bordered={true}
                            scroll={{ x: '100%', y: (tableHeight - 135) / 2 }}
                        />
                    </Col>
                </Row>
            </div>
            <BoardContextMenu
                {...boardContextMenuState}
                dataSource={boardContextMenuItems}
                onClickOption={(option, item) => {
                    setEditActionModalTitle(`${option.caption} от ${Moment(item?.dateTime).format("DD.MM.YYYY")}`);
                    setVisibleActionEditModal(true);
                }}
            />
        </div>
    );
}

export default Board;