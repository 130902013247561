import axios from 'axios';

import { IAuthData } from './AuthData';
import { IUserItem } from './UserItem';
import { IUserFilter } from './UserFilter';
import { IHistoryFilter } from './HistoryFilter';
import { IExtractFilter } from './ExtractFilter';
import { IOperatorItem } from './OperatorItem';
import { IHeaderItem } from './HeaderItem';
import { IScheduleItem } from './ScheduleItem';
import { IHistoryItem } from './HistoryItem';
import { ICalendarOptionItem } from './CalendarOptionItem';
import { IIntervalItem } from './IntervalItem';
import { IIntervalFilter } from './IntervalFilter';
import { IScheduleEditItem } from './ScheduleEditItem';
import { IScheduleEditResult } from './ScheduleEditResult';
import { IRequestStatusParameter } from './RequestStatusParameter';
import { IContextMenuItem } from './ContextMenuItem';
import { DayTypeDb } from './DayType';
import { IDayTypeFilter } from './DayTypeFilter';
import { IActionFormValues } from '../Component/ModalScheduleActionEdit';

//export const backendUrl = "http://localhost:5006";
export const backendUrl = "https://api.schedule.astrapage.ru";

// BUILD INFO: при выкладке меняем название контроллера
export const controllerName = 'schedule'; // Субподряд
//export const controllerName = 'scheduleexchange'; // Оператор

export const storeItemNameOfToken = 'apiToken';
export const client = axios.create({ baseURL: backendUrl });

// Set the AUTH token for any request
client.interceptors.request.use((config) => {

    const token = localStorage.getItem(storeItemNameOfToken);

    if (config.headers && token) {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
    }

    return config;
});

export function authUser(filter: IUserFilter) {
    return client.get<IUserItem>(`/api/${controllerName}/auth`, {
        params: filter
    });
}

export function fetchOperators(filter: IExtractFilter) {
    return client.get<IOperatorItem[]>(`/api/${controllerName}/getoperators`, {
        params: filter
    });
}

export function fetchHeaderItems(filter: IExtractFilter) {
    return client.get<IHeaderItem[]>(`/api/${controllerName}/getheaderitems`, {
        params: filter
    });
}

export function fetchScheduleItems(filter: IExtractFilter) {
    return client.get<IScheduleItem[]>(`/api/${controllerName}/getscheduleitems`, {
        params: filter
    });
}

export function fetchScheduleContextMenu(filter: IExtractFilter) {
    return client.get<IContextMenuItem[]>(`/api/${controllerName}/getschedulecontextmenu`, {
        params: filter
    });
}

export function fetchCalendarOptions(authData: IAuthData) {
    return client.get<ICalendarOptionItem[]>(`/api/${controllerName}/getcalendaroptions`, {
        params: authData
    });
}

export function fetchHistory(filter: IHistoryFilter) {
    return client.get<IHistoryItem[]>(`/api/${controllerName}/gethistory`, {
        params: filter
    });
}

export function fetchIntervals(filter: IIntervalFilter) {
    return client.get<IIntervalItem[]>(`/api/${controllerName}/getintervals`, {
        params: filter
    });
}

export function fetchDaysType(filter: IDayTypeFilter) {
    return client.get<DayTypeDb[]>(`/api/${controllerName}/getdaystype`, {
        params: filter
    });
}

export function fetchRequestStatusParameters(filter: IHistoryFilter) {
    return client.get<IRequestStatusParameter[]>(`/api/${controllerName}/getrequeststatusparameters`, {
        params: filter
    });
}

export const editItemErrorIdOk = 0;

export function editItem(item: IScheduleEditItem) {
    return client.post<IScheduleEditResult[]>(`/api/${controllerName}/editscheduleitems`, item);
}

export function saveAction(authData: IAuthData, values: IActionFormValues) {
    console.log();
    return client.post<IScheduleEditResult[]>(`/api/${controllerName}/editaction`, {
        ...authData,
        ...values
    });
}