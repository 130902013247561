import { Col, Form, Modal, Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Store } from "antd/lib/form/interface";
import TextArea from "antd/lib/input/TextArea";
import { Moment } from "moment";
import { Reducer, useEffect, useRef, useState, useReducer } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { IAuthData } from "../DbContext/AuthData";
import { DayType, DayTypeDb, DayTypeName } from "../DbContext/DayType";
import { IScheduleItem } from "../DbContext/ScheduleItem";
import { displaySysErrorInModal } from "../helpers";
import { useForm } from "../Hook/AntdFormWithUseFormTrigger";
import { nameof, sleep } from '../helpers';
import { isUndefined } from "util";
import { editItemErrorIdOk, fetchDaysType, saveAction } from "../DbContext/DbProvider";
import { FetchActionNames, fetchReducer, initialFetchState } from "../Reducers/FetchReducer";
import { IScheduleDataTableRow } from "../Entity/ScheduleDataTableRow";
import { IScheduleItemWithoutRNXY } from "../DbContext/ScheduleItemWithoutRNXY";

export const defaultNoSelectDayType = Number.MIN_SAFE_INTEGER;

export interface IDayEditData {
    scheduleId: number,
    dayType: DayType,
}

export interface IActionFormValues extends IDayEditData, Store {
    from: string,
    to: string,
    comment: string
}

export async function loadDaysType(authData?: IAuthData, scheduleId?: number, unsetValue: boolean = true) {

    if (isUndefined(authData)) {
        throw new Error('authData is undefined');
    }

    if (isUndefined(scheduleId)) {
        throw new Error('scheduleId is undefined');
    }

    let daysType = new Array<DayTypeDb>();

    const response = await fetchDaysType({
        ...authData,
        scheduleId: scheduleId
    });

    daysType = response.data;

    if (unsetValue && daysType.findIndex(e => e.id == defaultNoSelectDayType) == -1) {
        daysType.unshift({ id: defaultNoSelectDayType, caption: "Не установлен", icon: "" });
    }

    return daysType;
}

export interface ModalWindowScheduleActionEditProps {
    authData?: IAuthData,
    shiftItem?: IScheduleItem,
    title?: string,
    visible?: boolean,
    onVisibleChange?: (newState: boolean) => void,
    size?: SizeType,
    onUpdateShiftItems?: (newShiftItems: IScheduleItemWithoutRNXY[]) => void,
}

export function ModalWindowScheduleActionEdit(props: ModalWindowScheduleActionEditProps) {
    
    const { authData, shiftItem, title, visible, onVisibleChange, size, onUpdateShiftItems } = props;

    const [daysTypeState, dispatchDaysTypeState] = useReducer(fetchReducer, initialFetchState);
    const [visibleModal, setVisibleModal] = useState(false);
    const [disabledModal, setDisabledModel] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0
    });

    const draggleRef  = useRef<HTMLDivElement>(null);
    const [editForm]  = useForm<IActionFormValues>();

    const onSubmitForm = async (values: IActionFormValues) => {

        setConfirmLoading(true);

        try {

            if(!authData) {
                throw new Error("authData is undefined");
            }

            if(!shiftItem) {
                throw new Error("shiftItem is undefined");
            }

            const response = await saveAction(authData, {
                scheduleId: shiftItem.scheduleId,
                dayType: values.dayType,
                comment: values.comment,
                from: shiftItem.dateTime,
                to: shiftItem.dateTime
            });

            if (response.data.length < 1) {
                console.error('Not currect response', response);
                throw new Error('Response is not currect');
            }

            const newIShiftItems = response.data.map((editShiftItemResult) => {

                const { editResultErrorId, editResiltErrorMessage, ...shiftItemWithoutRNXY } = editShiftItemResult;

                if (editResultErrorId != editItemErrorIdOk) {
                    throw new Error(editResiltErrorMessage);
                }

                return shiftItemWithoutRNXY;
            });

            if (onUpdateShiftItems) {
                onUpdateShiftItems(newIShiftItems);
            }

            await sleep(1000);

            setVisibleModal(false);

            if (onVisibleChange) {
                onVisibleChange(false);
            }
        }
        catch (error) {
            displaySysErrorInModal(error);
        }
        finally {
            setConfirmLoading(false);
        }
    }

    const handleOk = async () => {

        if (editForm.isHooked()) {
            editForm.submit();
        }
        else {
            displaySysErrorInModal(new Error('editForm is undefined'));
        }
    };

    const handleCancel = () => {

        setVisibleModal(false);

        if (onVisibleChange) {
            onVisibleChange(false);
        }
    };

    const showModal = () => {
        setVisibleModal(true);
    };

    useEffect(() => {
        showModal();
    }, [shiftItem]);

    useEffect(() => {
        showModal();
    }, [visible]);

    useEffect(() => {

        if(!visible || !visibleModal) {
            return;
        }

        editForm.resetFields();

        dispatchDaysTypeState({
            type: FetchActionNames.FETCH_START
        });

        if (isUndefined(shiftItem)) {
            return;
        }

        let ignore = false;

        loadDaysType(authData, shiftItem?.scheduleId, false).then(
            (daysType) => {

                if (!ignore) {

                    dispatchDaysTypeState({
                        type: FetchActionNames.FETCH_SUCCESS,
                        data: daysType
                    });

                    editForm.setFields([{
                        name: nameof<IActionFormValues>('dayType'),
                        value: daysType[0].id
                    }]);
                }
            },
            (error) => {

                if (!ignore) {

                    dispatchDaysTypeState({
                        type: FetchActionNames.FETCH_ERROR,
                        error: error
                    });

                    displaySysErrorInModal(error);
                }
            },
        )

        return () => { ignore = true; }

    }, [visible, shiftItem]);

    const onStartDraggableModal = (event: DraggableEvent, uiData: DraggableData) => {

        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        setBounds({
            left: - targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: - targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <>
            <Modal
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                            paddingRight: 15
                        }}
                        onMouseOver={() => {
                            if (disabledModal) {
                                setDisabledModel(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabledModel(true);
                        }}
                        // fix eslintjsx-a11y/mouse-events-have-key-events
                        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                        onFocus={() => { }}
                        onBlur={() => { }}
                    // end
                    >
                        {title}
                    </div>
                }
                visible={visibleModal && visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                destroyOnClose={true}
                modalRender={modal => (
                    <Draggable
                        disabled={disabledModal}
                        bounds={bounds}
                        onStart={onStartDraggableModal}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            >
                    <Form<IActionFormValues>
                        form={editForm}
                        size={size}
                        onFinish={onSubmitForm}
                        //initialValues={initialValuesCurrect}
                        //onValuesChange={onValuesChangeProxy}
                    >
                        <div ref={editForm.formEventHookRef} className="FormEventWrap" />
                        <Col span={24}>
                            <Form.Item
                                label={`Тип`}
                                name={`${nameof<IActionFormValues>('dayType')}`}
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                                labelAlign='left'
                                rules={[{ required: true, message: 'Выберите доступное значение!' }]}
                            >
                                <Select<number>
                                    disabled={daysTypeState.loading}
                                    size={size}
                                    loading={daysTypeState.loading}
                                    showSearch
                                    placeholder="Тип дня"
                                    optionFilterProp="children"
                                    //onChange={onChangeDayType}
                                    //onSearch={onSearchDayType}
                                    filterOption={(input, option) =>
                                        option && option.children ?
                                            (option.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0 :
                                            false
                                    }
                                >
                                    {daysTypeState.data.length > 0 && daysTypeState.data.map((dayType, index) => {
                                        return (<Select.Option key={dayType.id} value={dayType.id}>{dayType.caption}</Select.Option>);
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label='Комментарий'
                                name={`${nameof<IActionFormValues>('comment')}`}
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 17 }}
                                labelAlign='left'
                                rules={[{ required: true, message: 'Комментарий не может быть пустым.' }]}
                            >
                                <TextArea rows={4} maxLength={500} showCount />
                            </Form.Item>
                        </Col>
                    </Form>
            </Modal>
        </>
    )
}

export default ModalWindowScheduleActionEdit;