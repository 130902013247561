import { Key, Reducer } from "react";

export type ErrorType = object | string | number;

export interface FetchItem {
    id: Key,
    caption: string
}

export interface FetchState<T = any> {
    loading: boolean,
    load: boolean,
    error: ErrorType | undefined | null,
    data: T[]
}

export const FetchActionNames = {
    FETCH_START:   'FETCH_START',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_ERROR:   'FETCH_ERROR'
} as const;

export type FetchAction<T = any> =
    | { type: 'FETCH_START' }
    | { type: 'FETCH_SUCCESS', data: T[] }
    | { type: 'FETCH_ERROR', error: ErrorType };

export const defaultNotSelectId = -1;

export const initialFetchState: FetchState<any> = {
    loading: true,
    load: false,
    error: null,
    data: []
}

export function fetchReducer<SI, A extends FetchAction<SI>>(state: FetchState<SI>, action: A) {

    switch (action.type) {

        case FetchActionNames.FETCH_START:
            return initialFetchState;

        case FetchActionNames.FETCH_SUCCESS:
            return {
                loading: false,
                load: true,
                error: null,
                data: action.data
            };

        case FetchActionNames.FETCH_ERROR:
            return {
                loading: false,
                load: false,
                error: action.error,
                data: []
            };

        default:
            return state;
    }
}