import { IScheduleItem } from "../DbContext/ScheduleItem";
import { IScheduleDataTableRow } from "./ScheduleDataTableRow";

export class DataTableItemProxy {

    /**
     * Return as a proxy with this object as its target.
     */
    static BuildProxy<TProxied extends IScheduleDataTableRow & object>(obj: TProxied) {

        let handler = {

            /**
             * This function is called whenever any property on the Proxy 
             * is called.
             * 
             * @param target the "parent" object; the object the proxy 
             *        virtualizes
             * @param prop the property called on the Proxy
            */
            get: function (target: IScheduleDataTableRow & { [prop: string]: any }, prop: string) {

                /* This will return the property on the "parent" object
                 */
                if (typeof target[prop] !== 'undefined')
                    return target[prop]

                const selector = prop.split("_");

                /*
                if (selector.length == 1
                && typeof target.items != "undefined"
                && target.items !== null) {

                    const itemProperty = selector[0] as unknown as keyof IShiftItem;
                    return target.items.map(item => item[itemProperty]);
;                }

                else
                */

                if (selector.length == 2
                    && typeof target.items != "undefined"
                    && target.items !== null) {

                    const itemId = Number.parseInt(selector[0]);
                    const itemProperty = selector[1] as unknown as keyof IScheduleItem;

                    return target.items[itemId][itemProperty];
                }

                return void 0;
            }
        }

        return new Proxy(obj, handler);
    }
}