import React from "react"
import { IScheduleItem } from "../DbContext/ScheduleItem"
import { IScheduleDataTableRow } from "../Entity/ScheduleDataTableRow"
import { UserOutlined } from "@ant-design/icons"


import './BoardContextMenu.css'
import { IContextMenuItem } from "../DbContext/ContextMenuItem"

export interface BoardContextMenuProps {
    record?: IScheduleDataTableRow,
    item?: IScheduleItem,
    visible?: boolean,
    dataSource?: IContextMenuItem[],
    onClickOption?: (option: IContextMenuItem, item?: IScheduleItem) => void
    x?: number,
    y?: number
}

const BoardContextMenu = ({record, item, visible, dataSource, onClickOption, x, y}: BoardContextMenuProps) =>
    visible && dataSource && dataSource.length > 0 ?
        <ul className="popup" style={{left: `${x}px`, top: `${y}px`}}>
            {dataSource.map((option) => (
                <li
                    key={option.id}
                    onClick={() => onClickOption && onClickOption(option, item)}
                >
                    {option.caption}
                </li>
            ))}
        </ul>
    :
        <></>;

export default BoardContextMenu