import { Form, FormInstance } from 'antd';
import { InternalNamePath, NamePath, StoreValue } from 'antd/lib/form/interface';
import React, { useRef, useState } from 'react';

export interface Meta {
    touched: boolean;
    validating: boolean;
    errors: string[];
    warnings: string[];
    name: InternalNamePath;
}

export interface InternalFieldData extends Meta {
    value: StoreValue;
}

export interface FieldData extends Partial<Omit<InternalFieldData, 'name'>> {
    name: NamePath
}

/**
 * Used by `setFields` config
 */
export interface FieldDataGeneric<T> extends Partial<Omit<FieldData, 'name'>> {
    name: keyof T;
}

/** Only return partial when type is not any */
declare type RecursivePartial<T> = T extends object ? {
    [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object ? RecursivePartial<T[P]> : T[P];
} : any;

export interface IFormEventDict {

}

export interface IResetFieldsEventDict extends IFormEventDict {
    fields?: NamePath[]
}

export interface ISetFieldsEventDict<T> extends IFormEventDict {
    fields: FieldDataGeneric<T>[]
}

export interface ISetFieldsValueEventDict<T> extends IFormEventDict {
    values: RecursivePartial<T>
}

export interface IFormInstanceWithTrigger<T> extends FormInstance<T> {
    //startEdit: () => void,
    //cancelEdit: () => void,
    isHooked: () => boolean,
    formEventHookRef: React.MutableRefObject<HTMLDivElement | null>
}

export const startEditEventName      = 'startedit';
export const cancelEditEventName     = 'canceledit';
export const resetFieldsEventName    = 'resetfields';
export const setFieldsEventName      = 'setfields';
export const setFieldsValueEventName = 'setfieldsvalue';

export type IFormEventInit<T> = Omit<Omit<CustomEventInit<T>, 'cancelable'>, 'bubbles'>;

class FormEvent<T> extends CustomEvent<T> implements Event {

    constructor(type: string, eventInitDict?: IFormEventInit<T>) {

        super(type, {
            bubbles: true,
            cancelable: false,
            ...eventInitDict
        });
    }
}

export class StartEditEvent extends FormEvent<IFormEventDict> {
    constructor(eventInitDict?: IFormEventInit<IFormEventDict>) {
        super(startEditEventName, eventInitDict);
    }
}

export class CancelEditEvent extends FormEvent<IFormEventDict> {
    constructor(eventInitDict?: IFormEventInit<IFormEventDict>) {
        super(cancelEditEventName, eventInitDict);
    }
}

export class ResetFieldsEvent extends FormEvent<IResetFieldsEventDict> {
    constructor(eventInitDict?: IFormEventInit<IResetFieldsEventDict>) {
        super(resetFieldsEventName, eventInitDict);
    }
}

export class SetFiledsEvent<T> extends FormEvent<ISetFieldsEventDict<T>> {
    constructor(eventInitDict?: IFormEventInit<ISetFieldsEventDict<T>>) {
        super(setFieldsEventName, eventInitDict);
    }
}

export class SetFiledsValueEvent<T> extends FormEvent<ISetFieldsValueEventDict<T>> {
    constructor(eventInitDict?: IFormEventInit<ISetFieldsValueEventDict<T>>) {
        super(setFieldsValueEventName, eventInitDict);
    }
}

export function isStartEditEvent(object: any): object is StartEditEvent {
    return object instanceof StartEditEvent;
}

export function isCancelEditEvent(object: any): object is CancelEditEvent {
    return object instanceof CancelEditEvent;
}

export function isResetFieldsEvent(object: any): object is ResetFieldsEvent {
    return object instanceof ResetFieldsEvent;
}

export function isSetFiledsEvent<T>(object: any): object is SetFiledsEvent<T> {
    return object instanceof SetFiledsEvent;
}

export function isSetFiledsValueEvent<T>(object: any): object is SetFiledsValueEvent<T> {
    return object instanceof SetFiledsValueEvent;
}

export function useForm<T = any>(form?: IFormInstanceWithTrigger<T>): [IFormInstanceWithTrigger<T>] {

    const [antdForm] = Form.useForm<T>();
    const internalWrapperRef = useRef<HTMLDivElement>(null);
    //const [bufLastFieldsValue, setBufLastFieldsValue] = useState<T>();

    const dispatchEvent = (event: Event) => {

        if (internalWrapperRef.current) {

            internalWrapperRef.current.dispatchEvent(event);
        }
    }

    const wrapForm: IFormInstanceWithTrigger<T> = React.useMemo(
        () =>
            form ?? {
                ...antdForm,
                resetFields: (fields?: NamePath[]) => {

                    antdForm.resetFields(fields);

                    dispatchEvent(new ResetFieldsEvent({
                        detail: {
                            fields: fields
                        }
                    }));
                },
                setFields: (fields: FieldData[]) => {

                    antdForm.setFields(fields);

                    dispatchEvent(new SetFiledsEvent({
                        detail: {
                            fields: fields as unknown as FieldDataGeneric<T>[]
                        }
                    }));
                },
                setFieldsValue: (values: RecursivePartial<T>) => {

                    antdForm.setFieldsValue(values);

                    dispatchEvent(new SetFiledsValueEvent({
                        detail: {
                            values: values
                        }
                    }));
                },
                isHooked: () => internalWrapperRef.current != null /* && console.log(internalWrapperRef) == void 0 */,
                /*
                startEdit: () => {

                    setBufLastFieldsValue(antdForm.getFieldsValue());

                    dispatchEvent(new StartEditEvent({
                    }));
                },
                cancelEdit: () => {

                    if (typeof bufLastFieldsValue != 'undefined') {
                        wrapForm.setFieldsValue(bufLastFieldsValue as unknown as RecursivePartial<T>);
                    }

                    dispatchEvent(new CancelEditEvent({
                    }));
                },
                */
                formEventHookRef: internalWrapperRef
            },
        [form, antdForm],
    );

    return [wrapForm];
}