import { Col, Form, FormProps, message, Modal, Row, Select, Switch } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useEffect, useReducer, useState } from 'react';
import { IAuthData } from '../DbContext/AuthData';
import { fetchIntervals } from '../DbContext/DbProvider';
import { IIntervalItem } from '../DbContext/IntervalItem';
import { cancelEditEventName, IFormInstanceWithTrigger, isCancelEditEvent, isResetFieldsEvent, isSetFiledsEvent, isSetFiledsValueEvent, isStartEditEvent, resetFieldsEventName, setFieldsEventName, setFieldsValueEventName, startEditEventName, useForm as useFormWithTrigger } from '../Hook/AntdFormWithUseFormTrigger';
import { nameof, sleep } from '../helpers';
import { isNullOrUndefined, isUndefined } from 'util';
import { IScheduleItem } from '../DbContext/ScheduleItem';
import TextArea from 'antd/lib/input/TextArea';
import { DayType } from '../DbContext/DayType';

const { Option } = Select;

export const defaultNoSelectIntervalValue = -1;

export interface IFormValues extends Store {
    dayOff: boolean,
    interval1Id: number,
    interval2Id: number,
    commentIn: string
}

export type LayoutType = 'vertical' | 'inline';

export interface IDataCombinator {
    userId?: number,
    dayType?: DayType,
    sCaption?: string,
    eCaption?: string
}

export interface IEditScheduleFormInstance extends IFormInstanceWithTrigger<IFormValues> { }

export interface IEditScheduleFormProps extends Omit<Omit<FormProps<IFormValues>, 'form'>, 'initialValues'> {
    form: IEditScheduleFormInstance,
    initialValues?: IFormValues,
    intervalsState: FetchIntervalsState,
    authData?: IAuthData,
    dataCombinator?: IDataCombinator,
    layout?: LayoutType
}

export const defaultInitialValues: IFormValues = {
    dayOff: false,
    interval1Id: -1,
    interval2Id: -1,
    commentIn: ''
};

export function findInIntervalsByCaption(intervals: IIntervalItem[], caption?: string) {

    if (!caption) {
        return -1;
    }

    const fixCaption = caption?.toLowerCase();
    return intervals.findIndex(x => x.caption.toLowerCase() === fixCaption);
}

export const useForm = (form?: IEditScheduleFormInstance) => useFormWithTrigger(form);

export function combineValues(form: IEditScheduleFormInstance, dataCombinator?: IDataCombinator, intervals?: IIntervalItem[]) {

    if (!intervals) {
        return false;
    }

    if (form && dataCombinator && dataCombinator.sCaption) {

        if (dataCombinator.dayType == DayType.DayOff || dataCombinator.sCaption.toLowerCase() === 'в') {

            form.setFieldsValue({ dayOff: true });

            return true;
        }

        const combinedValues: IFormValues = {
            dayOff: false,
            interval1Id: -1,
            interval2Id: -1,
            commentIn: ''
        }

        const findedInterval1Index = findInIntervalsByCaption(intervals, dataCombinator.sCaption);
        const findedInterval2Index = findInIntervalsByCaption(intervals, dataCombinator.eCaption);

        if (findedInterval1Index != -1) {
            combinedValues.interval1Id = intervals[findedInterval1Index].id;
        }

        if (findedInterval2Index != -1) {
            combinedValues.interval2Id = intervals[findedInterval2Index].id;
        }

        form.setFieldsValue(combinedValues);
        return true;
    }

    return false;
}

export type ErrorType = object | string | number;

export const FetchIntervalsActionNames = {
    FETCH_INTERVALS_START:   'FETCH_INTERVALS_START',
    FETCH_INTERVALS_SUCCESS: 'FETCH_INTERVALS_SUCCESS',
    FETCH_INTERVALS_ERROR:   'FETCH_INTERVALS_ERROR'
} as const;

export type FetchAction =
    | { type: 'FETCH_INTERVALS_START' }
    | { type: 'FETCH_INTERVALS_SUCCESS', intervals: IIntervalItem[] }
    | { type: 'FETCH_INTERVALS_ERROR', error: ErrorType };

export type Reducer<S> = (s: S, a: FetchAction) => S

export interface FetchIntervalsState {
    loading: boolean,
    error: ErrorType | undefined | null,
    intervals: IIntervalItem[]
}

export const initialFetchIntervalsState: FetchIntervalsState = {
    loading: true,
    error: null,
    intervals: [{ id: defaultNoSelectIntervalValue, caption: "Загрузка..." }]
}

export const intervalReducer: Reducer<FetchIntervalsState> = (state, action) => {

    switch (action.type) {

        case FetchIntervalsActionNames.FETCH_INTERVALS_START:
            return initialFetchIntervalsState;

        case FetchIntervalsActionNames.FETCH_INTERVALS_SUCCESS:
            return {
                loading: false,
                error: null,
                intervals: action.intervals
            };

        case FetchIntervalsActionNames.FETCH_INTERVALS_ERROR:
            return {
                loading: false,
                error: action.error,
                intervals: []
            };

        default:
            return state;
    }
}

export async function loadIntervals(authData?: IAuthData, operatorId?: number) {

    if (isUndefined(authData)) {
        throw new Error('authData is undefined');
    }

    if (isUndefined(operatorId)) {
        throw new Error('operatorId is undefined');
    }

    let intervals = new Array<IIntervalItem>();

    const response = await fetchIntervals({
        ...authData,
        operatorId: operatorId
    });

    intervals = response.data;

    if (intervals.findIndex(e => e.id == defaultNoSelectIntervalValue) == -1) {
        intervals.unshift({ id: defaultNoSelectIntervalValue, caption: "Не установлен" });
    }

    return intervals;
}

/*
export function getShiftItemsForValues(prev?: IScheduleItem[], values?: IFormValues, intervals?: IIntervalItem[]): IScheduleItem[] {

    if (isUndefined(prev)) {
        throw new Error('Prev is undefined');
    }

    if (isUndefined(values)) {
        throw new Error('values is undefined');
    }

    if (isUndefined(intervals)) {
        throw new Error('intervals is undefined');
    }

    if (values.dayOff) {
        return prev.map((prevItem) => {
            return {
                ...prevItem,
                dayType: DayType.DayOff,
                sCaption: 'В',
                eCaption: ''
            }
        });
    }

    if (intervals.length == 0) {
        throw new Error('intervals is empty');
    }

    const newSCaption = intervals.find(x => x.id == values.interval1Id);
    const newECaption = intervals.find(x => x.id == values.interval2Id);

    if (isUndefined(newSCaption)) {
        throw new Error('interval1Id not found');
    }

    if (isUndefined(newSCaption)) {
        throw new Error('interval1Id not found');
    }

    if (isUndefined(newECaption)) {
        throw new Error('interval2Id not found');
    }

    if (newSCaption.id == -1) {
        return prev.map((prevItem) => {
            return {
                ...prevItem,
                dayType: DayType.DayOff,
                sCaption: 'В',
                eCaption: ''
            }
        });
    }

    return prev.map((prevItem) => {
        return {
            ...prevItem,
            dayType: DayType.Work,
            sCaption: newSCaption.caption,
            eCaption: newECaption.id == -1 ? '%HOURS%' : newECaption.caption
        }
    });
}

export function getShiftItemForValues(prev?: IScheduleItem, values?: IFormValues, intervals?: IIntervalItem[]): IScheduleItem {

    if (isUndefined(prev)) {
        throw new Error('Prev is undefined');
    }

    if (isUndefined(values)) {
        throw new Error('values is undefined');
    }

    if (isUndefined(intervals)) {
        throw new Error('intervals is undefined');
    }

    if (values.dayOff) {
        return {
            ...prev,
            sCaption: 'В',
            eCaption: ''
        }
    }

    if (intervals.length == 0) {
        throw new Error('intervals is empty');
    }

    const newSCaption = intervals.find(x => x.id == values.interval1Id);
    const newECaption = intervals.find(x => x.id == values.interval2Id);

    if (isUndefined(newSCaption)) {
        throw new Error('interval1Id not found');
    }

    if (isUndefined(newSCaption)) {
        throw new Error('interval1Id not found');
    }

    if (isUndefined(newECaption)) {
        throw new Error('interval2Id not found');
    }

    if (newSCaption.id == -1) {
        return {
            ...prev,
            sCaption: 'В',
            eCaption: ''
        }
    }

    return {
        ...prev,
        sCaption: newSCaption.caption,
        eCaption: newECaption.id == -1 ? '%HOURS%' : newECaption.caption
    }
}
*/

export function EditScheduleForm(props: IEditScheduleFormProps) {

    const { form, intervalsState, authData, dataCombinator, size, initialValues, layout, onValuesChange, ...formProps } = props;

    const initialValuesCurrectBuf = initialValues ?? defaultInitialValues;
    const [disabledIntervals, setDisabledIntervals] = useState<boolean>(initialValuesCurrectBuf.dayOff);
    const [disabledInterval2, setDisabledInterval2] = useState<boolean>(initialValuesCurrectBuf.dayOff || initialValuesCurrectBuf.interval1Id == defaultNoSelectIntervalValue);
    const [initialValuesCurrect, setInitialValuesCurrect] = useState(initialValuesCurrectBuf);

    const onValuesChangeProxy = (changedValues: any, values: IFormValues) => {

        setDisabledIntervals(values.dayOff);
        setDisabledInterval2(values.dayOff || values.interval1Id == defaultNoSelectIntervalValue);

        if (onValuesChange) {
            onValuesChange(changedValues, values);
        }
    }

    useEffect(() => {

        if (initialValues) {
            setInitialValuesCurrect(initialValues);
        }

    }, [initialValues]);

    function onChangeInterval1(value: number) {
        //console.log(`selected ${value}`);
    }

    function onSearchInterval1(val: string) {
        //console.log('search:', val);
    }

    function onChangeInterval2(value: number) {
        //console.log(`selected ${value}`);
    }

    function onSearchInterval2(val: string) {
        //console.log('search:', val);
    }

    const onResetFields = (event: Event) => {

        //console.log('onResetFields', event);

        if (isResetFieldsEvent(event)) {

            if (isUndefined(event.detail.fields) || event.detail.fields.indexOf(nameof<IFormValues>('dayOff')) != -1) {

                setDisabledIntervals(initialValuesCurrect.dayOff);
                setDisabledInterval2(initialValuesCurrect.dayOff || initialValuesCurrect.interval1Id == defaultNoSelectIntervalValue);
            }
        }
    }

    const onSetFields = (event: Event) => {

        //console.log('onSetFields', event);

        if (isSetFiledsEvent<IFormValues>(event)) {

            const findDayOffField = event.detail.fields.filter(x => x.name == nameof<IFormValues>('dayOff'));
            if (findDayOffField.length > 0) {
                const dayOffField = findDayOffField[0];
                setDisabledIntervals(dayOffField.value);
            }
        }
    }

    const onSetFieldsValue = (event: Event) => {

        //console.log('onSetFieldsValue', event);

        if (isSetFiledsValueEvent<IFormValues>(event)) {

            if (!isUndefined(event.detail.values.dayOff)) {

                setDisabledIntervals(event.detail.values.dayOff);
                setDisabledInterval2(event.detail.values.dayOff || event.detail.values.interval1Id == defaultNoSelectIntervalValue);
            }
        }
    }

    useEffect(() => {

        const ref = form.formEventHookRef;

        if (ref.current) {
            ref.current.addEventListener(resetFieldsEventName, onResetFields);
            ref.current.addEventListener(setFieldsEventName, onSetFields);
            ref.current.addEventListener(setFieldsValueEventName, onSetFieldsValue);
        }

        return () => {

            if (ref.current) {
                ref.current.removeEventListener(resetFieldsEventName, onResetFields);
                ref.current.removeEventListener(setFieldsEventName, onSetFields);
                ref.current.removeEventListener(setFieldsValueEventName, onSetFieldsValue);
            }
        }

    }, [form.formEventHookRef]);

    useEffect(() => {

        if (!intervalsState.loading && !isNullOrUndefined(intervalsState.error)) {

            combineValues(form, dataCombinator, intervalsState.intervals);
        }

    }, [dataCombinator]);

    useEffect(() => {

        if (!intervalsState.loading && isNullOrUndefined(intervalsState.error)) {

            combineValues(form, dataCombinator, intervalsState.intervals);
        }

    }, [intervalsState.intervals]);

    return (
        <Form<IFormValues>
            {...formProps}
            form={form}
            size={size}
            initialValues={initialValuesCurrect}
            onValuesChange={onValuesChangeProxy}
        >
            <div ref={form.formEventHookRef} className="FormEventWrap" />
            <Form.Item
                label="Выходной"
                valuePropName="checked"
                name={`${nameof<IFormValues>('dayOff')}`}
                style={layout == 'inline' ? { marginBottom: 10 } : {}}
                labelCol={layout == 'inline' ? { } : { span: 5 }}
                wrapperCol={layout == 'inline' ? {} : { span: 19 }}
                labelAlign='left'
            >
                <Switch size={size == 'small' ? 'small' : 'default'} disabled={intervalsState.loading} />
            </Form.Item>
            <Row gutter={15}>
                <Col span={layout == 'inline' ? 12 : 24}>
                    <Form.Item
                        label={`Интервал 1${(layout == 'inline') ? '::' : ''}`}
                        name={`${nameof<IFormValues>('interval1Id')}`}
                        labelCol={layout == 'inline' ? { span: 24 } : { span: 5 }}
                        wrapperCol={layout != 'inline' ? { span: 19 } : {}}
                        labelAlign='left'
                    >
                        <Select<number>
                            disabled={disabledIntervals || intervalsState.loading}
                            size={size}
                            loading={intervalsState.loading}
                            showSearch
                            placeholder="Укажите интервал"
                            optionFilterProp="children"
                            onChange={onChangeInterval1}
                            onSearch={onSearchInterval1}
                            filterOption={(input, option) =>
                                option && option.children ?
                                    (option.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0 :
                                    false
                            }
                        >
                            {intervalsState.intervals.length > 0 && intervalsState.intervals.map((intervalItem, index) => {
                                return (<Option key={intervalItem.id} value={intervalItem.id}>{intervalItem.caption}</Option>);
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={layout == 'inline' ? 12 : 24}>
                    <Form.Item
                        label={`Интервал 2${(layout == 'inline') ? '::' : ''}`}
                        name={`${nameof<IFormValues>('interval2Id')}`}
                        labelCol={layout == 'inline' ? { span: 24 } : { span: 5 }}
                        wrapperCol={layout != 'inline' ? { span: 19 } : {}}
                        labelAlign='left'
                    >
                        <Select<number>
                            disabled={disabledInterval2 || disabledIntervals || intervalsState.loading}
                            size={size}
                            loading={intervalsState.loading}
                            showSearch
                            placeholder="Укажите интервал"
                            optionFilterProp="children"
                            onChange={onChangeInterval2}
                            onSearch={onSearchInterval2}
                            filterOption={(input, option) =>
                                option && option.children ?
                                    (option.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0 :
                                    false
                            }
                        >
                            {intervalsState.intervals.length > 0 && intervalsState.intervals.map((intervalItem, index) => {
                                return (<Option key={intervalItem.id} value={intervalItem.id}>{intervalItem.caption}</Option>);
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                {layout != 'inline' &&
                    <Col span={24}>
                        <Form.Item
                            label='Комментарий'
                            name={`${nameof<IFormValues>('commentIn')}`}
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 19 }}
                            labelAlign='left'
                        >
                            <TextArea rows={4} maxLength={500} showCount />
                        </Form.Item>
                    </Col>
                }
            </Row>
        </Form>
    )
}

export default EditScheduleForm;