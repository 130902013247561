import { Modal, Table, TableColumnType } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { TableRowSelection } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { isUndefined } from 'util';
import { IAuthData } from '../DbContext/AuthData';
import { DayType } from '../DbContext/DayType';
import { editItem, editItemErrorIdOk } from '../DbContext/DbProvider';
import { IScheduleItem } from '../DbContext/ScheduleItem';
import { IScheduleDataTableRow } from '../Entity/ScheduleDataTableRow';
import { displaySysErrorInModal, nameof, sleep } from '../helpers';
import { defaultNoSelectIntervalValue, EditScheduleForm, FetchIntervalsActionNames, IDataCombinator, IFormValues, initialFetchIntervalsState, intervalReducer, loadIntervals, useForm } from './EditScheduleForm';

export interface ModalWindowScheduleMultiItemEditProps {
    authData?: IAuthData,
    data?: IScheduleDataTableRow,
    visible?: boolean,
    onVisibleChange?: (newState: boolean) => void,
    onUpdateShiftItems?: (newShiftItems: IScheduleItem[]) => void,
    size?: SizeType
}

interface ITableRow extends IScheduleItem {
    newSCaption?: string,
    newECaption?: string
}

interface ITableColumn extends TableColumnType<ITableRow> { }

export function ModalScheduleMultiItemEdit(props: ModalWindowScheduleMultiItemEditProps) {

    const { authData, data, size, onVisibleChange, onUpdateShiftItems, visible } = props;

    const [intervalsState, dispatchIntervalsState] = useReducer(intervalReducer, initialFetchIntervalsState);

    const [dataCombinator, setDataCombinator] = useState<IDataCombinator>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [visibleModal, setVisibleModal] = useState(false);
    const [disabledModal, setDisabledModel] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0
    });

    const [tableData, setTableData] = useState<ITableRow[]>([]);
    const tableColumns: ITableColumn[] = [
        {
            title: 'Дата',
            dataIndex: `${nameof<ITableRow>("dateTime")}`,
            render: (text, record, index) => record ? moment(record.dateTime).format("DD.MM.YYYY") : text
        },
        {
            title: 'График фактический',
            render: (text, record, index) =>
                record ?
                    <span>
                        <span>{record.sCaption}</span>
                        <span>{record.eCaption}</span>
                    </span> :
                    text
        },
        /*
        {
            title: 'График измененный',
            render: (text, record, index) => record ?
                <span>
                    <span>{record.newSCaption}</span>
                    <span>{record.newECaption}</span>
                </span> :
                text
        },
        */
    ];

    const handleOk = () => {

        setConfirmLoading(true);

        if (editForm.isHooked()) {
            editForm.submit();
        }
        else {
            displaySysErrorInModal(new Error('editForm is undefined'));
        }
    };

    const handleCancel = () => {

        setVisibleModal(false);
        setSelectedRowKeys([]);

        if (onVisibleChange) {
            onVisibleChange(false);
        }
    };

    const [editForm] = useForm();
    const draggleRef = useRef<HTMLDivElement>(null);
    
    const rowSelection: TableRowSelection<ITableRow> = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.isDisabled,
            name: record.dateTime,
        }),
    };

    const onStartDraggableModal = (event: DraggableEvent, uiData: DraggableData) => {

        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        setBounds({
            left: - targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: - targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const onFinishEditForm = async (values: IFormValues) => {

        try {

            if (!values.dayOff &&
                values.interval1Id == defaultNoSelectIntervalValue) {
                Modal.warning({
                    title: 'Хммм!',
                    content: 'Интервал должен быть заполнен для рабочего дня.'
                });
                return;
            }

            if (selectedRowKeys.length < 1) {
                Modal.warning({
                    title: 'Хммм!',
                    content: 'Не указаны дни для редактирования. Отметьте флажком в таблице нужные дни.'
                });
                return;
            }

            if (isUndefined(authData)) {
                throw new Error('authData is undefined');
            }

            if (isUndefined(data)) {
                throw new Error('data is undefined');
            }

            if (isUndefined(data.items)) {
                throw new Error('items is undefined');
            }

            const shiftItems = data.items.filter(item =>
                selectedRowKeys.findIndex((selectedRow) =>
                    selectedRow == item.calendarDayId) != -1
            );
            
            if (shiftItems.length < 1) {
                throw new Error('shiftItems length less 1');
            }

            //const newIShiftItems = getShiftItemsForValues(shiftItems, values, intervalsState.intervals);

            const response = await editItem({
                ...authData,
                operatorId: data.operatorId,
                calendarDaysId: selectedRowKeys as unknown as number[],
                dayType: values.dayOff ? DayType.DayOff : DayType.Work,
                interval1Id: values.dayOff ? defaultNoSelectIntervalValue : values.interval1Id,
                interval2Id: values.dayOff ? defaultNoSelectIntervalValue : values.interval2Id,
                commentIn: values.commentIn
            });

            if (response.data.length < 1) {
                console.error('Not currect response', response);
                throw new Error('Response is not currect');
            }

            const newIShiftItems = response.data.map((editShiftItemResult) => {

                const { editResultErrorId, editResiltErrorMessage, ...shiftItemWithoutRNXY } = editShiftItemResult;
                const shiftItem = shiftItems.find(item => item && item.scheduleId == editShiftItemResult.scheduleId);

                if (isUndefined(shiftItem)) {
                    throw new Error(`Not found ShiftItem with scheduleId: ${editShiftItemResult.scheduleId}`);
                }

                if (editResultErrorId != editItemErrorIdOk) {
                    throw new Error(editResiltErrorMessage);
                }

                return {
                    ...shiftItem,
                    ...shiftItemWithoutRNXY
                }
            });

            if (onUpdateShiftItems) {
                onUpdateShiftItems(newIShiftItems);
            }

            await sleep(1000);

            setVisibleModal(false);

            if (onVisibleChange) {
                onVisibleChange(false);
            }
        }
        catch (ex: any) {

            const message = isUndefined(ex.message) ? ex : ex.message;

            displaySysErrorInModal(ex, {
                title: 'Упс!',
                content: `Ошибка: ${message}`
            });
        }
        finally {
            setConfirmLoading(false);
        }
    }

    useEffect(() => {

        setSelectedRowKeys([]);

        editForm.resetFields();

        dispatchIntervalsState({
            type: FetchIntervalsActionNames.FETCH_INTERVALS_START
        });

        if (isUndefined(data)) {
            setDataCombinator({});
            setTableData([]);
            return;
        }

        setDataCombinator({ userId: data?.operatorId });

        if (data && data.items) {
            const items = new Array<ITableRow>();
            data.items.map((item) => {
                items.push(item);
            });
            setTableData(items);
        }
        else {
            setTableData([]);
        }

        let ignore = false;

        loadIntervals(authData, data?.operatorId).then(
            (intervals) => {

                if (!ignore) {

                    dispatchIntervalsState({
                        type: FetchIntervalsActionNames.FETCH_INTERVALS_SUCCESS,
                        intervals: intervals
                    });
                }
            },
            (error) => {

                if (!ignore) {

                    displaySysErrorInModal(error);

                    dispatchIntervalsState({
                        type: FetchIntervalsActionNames.FETCH_INTERVALS_ERROR,
                        error: error
                    });
                }
            },
        );

        return () => { ignore = true; }

    }, [data]);

    useEffect(() => {

        setSelectedRowKeys([]);
        editForm.resetFields();
        setVisibleModal(true);

    }, [visible]);

    return (
        <>
            <Modal
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                        }}
                        onMouseOver={() => {
                            if (disabledModal) {
                                setDisabledModel(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabledModel(true);
                        }}
                        // fix eslintjsx-a11y/mouse-events-have-key-events
                        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                        onFocus={() => { }}
                        onBlur={() => { }}
                    // end
                    >
                        {`Редактирование графика: ${data?.operatorName}`}
                    </div>
                }
                visible={visibleModal && visible}
                onOk={handleOk}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                modalRender={modal => (
                    <Draggable
                        disabled={disabledModal}
                        bounds={bounds}
                        onStart={onStartDraggableModal}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            >
                <EditScheduleForm
                    form={editForm}
                    intervalsState={intervalsState}
                    authData={authData}
                    dataCombinator={dataCombinator}
                    size={size}
                    onFinish={onFinishEditForm}
                    layout='inline'
                />
                <Table<ITableRow>
                    rowKey={`${nameof<ITableRow>("calendarDayId")}`}
                    size={size}
                    columns={tableColumns}
                    dataSource={tableData}
                    rowSelection={{
                        selectedRowKeys,
                        type: selectionType,
                        ...rowSelection,
                    }}
                    pagination={false}
                    bordered={true}
                    scroll={{ x: '100%', y: 400 }}
                />
            </Modal>
        </>
    );
}

export default ModalScheduleMultiItemEdit;