import { Modal, ModalFuncProps } from "antd";
import { isNullOrUndefined } from "util";

export const nameof = function <T>(name: keyof T) { return name; }
export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function displaySysErrorInModal(err: any, props?: ModalFuncProps) {

    console.error(err);

    Modal.error(props ?? {
        title: 'Упс!',
        content: 'Возникла системная ошибка, попробуйте перезагрузить страницу.'
    });
}

export function compileClassNames(names: string[]) {
    return names
        .filter(c => !isNullOrUndefined(c) && c != '')
        .join(' ');
}