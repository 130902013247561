import { Modal } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { isUndefined } from 'util';
import { IAuthData } from '../DbContext/AuthData';
import { DayType } from '../DbContext/DayType';
import { editItem, editItemErrorIdOk } from '../DbContext/DbProvider';
import { IScheduleItem } from '../DbContext/ScheduleItem';
import { displaySysErrorInModal, sleep } from '../helpers';
import EditScheduleForm, { defaultNoSelectIntervalValue, FetchIntervalsActionNames, IFormValues, initialFetchIntervalsState, intervalReducer, loadIntervals, useForm } from './EditScheduleForm';

export interface ModalWindowScheduleItemEditProps {
    authData?: IAuthData,
    shiftItem?: IScheduleItem,
    title?: string,
    visible?: boolean,
    onVisibleChange?: (newState: boolean) => void,
    size?: SizeType,
    onUpdateShift?: (newShiftItem: IScheduleItem) => void
}

export function ModalScheduleItemEdit(props: ModalWindowScheduleItemEditProps) {

    const { authData, shiftItem, title, visible, onVisibleChange, size, onUpdateShift } = props;

    const [intervalsState, dispatchIntervalsState] = useReducer(intervalReducer, initialFetchIntervalsState);
    const [visibleModal, setVisibleModal] = useState(false);
    const [disabledModal, setDisabledModel] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0
    });

    const draggleRef  = useRef<HTMLDivElement>(null);
    const [editForm]  = useForm();

    const onFinishEditForm = async (values: IFormValues) => {

        try {

            if (!values.dayOff &&
                values.interval1Id == defaultNoSelectIntervalValue) {
                Modal.warning({
                    title: 'Хммм!',
                    content: 'Интервал должен быть заполнен для рабочего дня.'
                });
                return;
            }

            if (isUndefined(authData)) {
                throw new Error('authData is undefined');
            }

            if (isUndefined(shiftItem)) {
                throw new Error('shiftItem is undefined');
            }

            const response = await editItem({
                ...authData,
                operatorId: shiftItem.userId,
                calendarDaysId: [shiftItem.calendarDayId],
                dayType: values.dayOff ? DayType.DayOff : DayType.Work,
                interval1Id: values.dayOff ? defaultNoSelectIntervalValue : values.interval1Id,
                interval2Id: values.dayOff ? defaultNoSelectIntervalValue : values.interval2Id,
                commentIn: values.commentIn
            });

            if (response.data.length != 1) {
                console.error('Not currect response', response);
                throw new Error('Response is not currect');
            }

            // TODO
            //const newIShiftItem = getShiftItemForValues(shiftItem, values, intervalsState.intervals);

            const { editResultErrorId, editResiltErrorMessage, ...shiftItemWithoutRNXY } = response.data[0];

            if (editResultErrorId != editItemErrorIdOk) {
                throw new Error(editResiltErrorMessage);
            }

            const newIShiftItem = {
                ...shiftItem,
                ...shiftItemWithoutRNXY
            }

            if (onUpdateShift) {
                onUpdateShift(newIShiftItem);
            }

            await sleep(1000);

            setVisibleModal(false);

            if (onVisibleChange) {
                onVisibleChange(false);
            }
        }
        catch (ex: any) {

            const message = isUndefined(ex.message) ? ex : ex.message;

            displaySysErrorInModal(ex, {
                title: 'Упс!',
                content: `Ошибка: ${message}`
            });
        }
        finally {
            setConfirmLoading(false);
        }
    }

    const handleOk = () => {

        setConfirmLoading(true);

        if (editForm.isHooked()) {
            editForm.submit();
        }
        else {
            displaySysErrorInModal(new Error('editForm is undefined'));
        }
    };

    const handleCancel = () => {

        setVisibleModal(false);

        if (onVisibleChange) {
            onVisibleChange(false);
        }
    };

    const showModal = () => {
        setVisibleModal(true);
    };

    const onStartDraggableModal = (event: DraggableEvent, uiData: DraggableData) => {

        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        setBounds({
            left: - targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: - targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    useEffect(() => {
        showModal();
    }, [shiftItem]);

    useEffect(() => {
        showModal();
    }, [visible]);

    useEffect(() => {

        editForm.resetFields();

        dispatchIntervalsState({
            type: FetchIntervalsActionNames.FETCH_INTERVALS_START
        });

        if (isUndefined(shiftItem)) {
            return;
        }

        let ignore = false;

        loadIntervals(authData, shiftItem?.userId).then(
            (intervals) => {

                if (!ignore) {

                    dispatchIntervalsState({
                        type: FetchIntervalsActionNames.FETCH_INTERVALS_SUCCESS,
                        intervals: intervals
                    });
                }
            },
            (error) => {

                if (!ignore) {

                    dispatchIntervalsState({
                        type: FetchIntervalsActionNames.FETCH_INTERVALS_ERROR,
                        error: error
                    });

                    displaySysErrorInModal(error);
                }
            },
        )

        return () => { ignore = true; }

    }, [shiftItem]);

    return (
        <>
            <Modal
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                            paddingRight: 15
                        }}
                        onMouseOver={() => {
                            if (disabledModal) {
                                setDisabledModel(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabledModel(true);
                        }}
                        // fix eslintjsx-a11y/mouse-events-have-key-events
                        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                        onFocus={() => { }}
                        onBlur={() => { }}
                    // end
                    >
                        {title}
                    </div>
                }
                visible={visibleModal && visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                destroyOnClose={true}
                modalRender={modal => (
                    <Draggable
                        disabled={disabledModal}
                        bounds={bounds}
                        onStart={onStartDraggableModal}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
            >
                <EditScheduleForm
                    form={editForm}
                    intervalsState={intervalsState}
                    authData={authData}
                    dataCombinator={shiftItem}
                    size={size}
                    onFinish={onFinishEditForm}
                />
            </Modal>
        </>
    );
}

export default ModalScheduleItemEdit;
